<!--
 * @Author: pengyu
 * @Date: 2020-07-24 14:34:44
 * @LastEditors: pengyu
 * @LastEditTime: 2021-07-27 09:14:17
 * @Description: 子项控件
-->
<template>
  <div class="sub-item-container">
    <el-collapse v-model="expandName">
      <el-collapse-item :title="column.name" :name="column.uuid">
        <div class="table-wrap">
          <en-table
            ref="subTable"
            :data="column.tableData"
            :head-end-config="headAddConfig"
            no-data-icon-size="small"
            @row-click="handleRowClick"
          >
            <en-table-column
              v-for="(item,index) in column.showContent"
              :key="index"
              :field="item.field"
              :title="item.name"
              min-width="220px"
            >
              <template #edit="{row, rowIndex}">
                <BusinessColumnRendering
                  :key="getColumnInRow(row,item.field, rowIndex).uuid"
                  :column="getColumnInRow(row,item.field, rowIndex)"
                  :businessData="row"
                  :hideLabel="true"
                  :check-prop="'showSections.0.areas.'+rowIndex"
                  class="is-edit"
                ></BusinessColumnRendering>
              </template>
            </en-table-column>
            <template #hoverRow="{rowIndex}">
              <en-expand-buttons
                v-if="column.editable"
                :data="buttonData"
                @button-click="handleButtonClick(rowIndex)"
              >
              </en-expand-buttons>
              <!-- <en-icon name="shanchu-liebiao" size="small" @click.native="deleteItem(rowIndex)" v-if="column.editable"></en-icon> -->
            </template>
          </en-table>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import BusinessColumnRendering from "..";
import { AssignmentOptions, BusinessFieldType, BusinessDataOpenType } from "../../../data/BusinessCommonHeader";

export default {
  name: "BMSubItemColumn",
  props: {
    column: {
      type: Object,
      require: true,
      default: () => {}
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    BusinessColumnRendering
  },
  computed: {
    headAddConfig() {
      if (!this.column.editable) {
        return {};
      }
      return { type: "add", rowDataMethod: this.addSubItem };
    }
  },
  data() {
    return {
      expandName: this.column.uuid,
      buttonData: [{ icon: "shanchu-liebiao" }],
      busData: cloneDeep(this.businessData)
    };
  },
  methods: {
    /**
     * @description: 添加一行
     */
    addSubItem() {
      // 当前businessData有可能已经发生改变，需要使用原始 父级添加的
      const bu = cloneDeep(this.busData);
      const fields = bu.sections[0].fields;
      const ary = [];
      const { showContent } = this.column;
      showContent?.forEach((item) => {
        const col = fields.filter((fd) => fd.field === item.field)[0];
        if (item.isDefaultOut === "0") { // 设置了默认带出
          if (Number(item.fieldType) === BusinessFieldType.inputCommon && (col.codeRule && col.codeRule.length)) { // 单行文本设置了编码规则  不赋值
            col.updateValueWithOptions("", AssignmentOptions.subItemDefaultOut);
          }
        } else {
          col.updateValueWithOptions("", AssignmentOptions.subItemDefaultOut);
        }
        ary.push(col);
      });
      bu.sections[0].fields = ary;
      bu.dataLevel = 1;
      bu.id = "";
      this.column.tableData.push(bu);
      return null;
    },
    /**
     * @description: 删除一行
     * @param index 索引
     */
    deleteItem(index) {
      this.column.tableData.splice(index, 1);
      if (this.column.tableData.length === 0) {
        this.$refs.subTable.refreshColumn();
      }
    },
    /**
     * @description: 获取行中的column值
     * @param row 行数据
     * @param field 字段
     * @param rowIndex 索引
     */
    getColumnInRow(row, field) {
      const { sections } = row;
      const { fields } = sections[0];
      const re = fields.filter((itm) => itm.field === field)[0];
      re.issub = 2;
      return re;
    },
    /**
     * @description: 子项跳转
     * @param {type}
     * @return {type}
     */
    handleRowClick({ row }) {
      const { templateId } = this.column;
      const { openType, businessType, pageType } = this.businessData.businessParams;
      const { id } = row;
      if (openType.toString() === BusinessDataOpenType.view) {
        this.$router.push({
          path: "/businessModel/detail",
          query: {
            id, templateId, openType, pageType, businessType, viewType: this.$route.query.viewType
          }
        });
      }
    },
    /**
     * @description: 删除一行
     * @param rowIndex 索引
     */
    handleButtonClick(rowIndex) {
      this.column.tableData.splice(rowIndex, 1);
      if (this.column.tableData.length === 0) {
        this.$refs.subTable.refreshColumn();
      }
    }
  }
};
</script>

<style lang='scss' scoped>
.sub-item-container {
  & /deep/ .el-collapse-item__header{
    font-weight: 600;
  }
  .table-wrap {
    & /deep/ .vxe-head-end-wrapper{
      padding: 0 12px;
      .en-icon{
        cursor: pointer;
      }
    }
    & /deep/ .vxe-table--empty-block{
      min-height: 0;
    }
    & /deep/ .el-input--prefix{
      .el-input__inner{
        padding-left: 40px !important;
      }
    }
    & /deep/ .progress-container-issub{
      white-space: nowrap;
      padding-left: 12px;
    }
    & /deep/ .business-column-rendering{
      align-items: center;
    }
    & /deep/ .en-expand-buttons{
      padding-right: 20px;
    }
  }
}
</style>
